import {
	IActor,
	IBaseApiModel,
	IBlogCampaignApprovalRequest,
	ICampaignApprovalRequest,
	ITemplateReference,
	IUserReference,
} from '.';

export enum BlogStatus {
	Queued = 'Queued',
	Pending = 'Pending',
	Completed = 'Completed',
	Cancelled = 'Cancelled',
	Failed = 'Failed',
	Draft = 'Draft',
}

export interface IBlogApprovalRequest {
	blog: ICampaignApprovalRequest;
}

export interface IBlogReportView extends IBaseApiModel {
	actor?: IActor;
	author?: IUserReference;
	approvalRequests: IBlogCampaignApprovalRequest[];
	cancelledDate?: string;
	creator?: IUserReference;
	scheduledSendDate: string;
	status: BlogStatus;
	title: string;
	templateReference?: ITemplateReference;
}
