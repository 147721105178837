import { AchievementType, FilterOperator, IBaseApiTypeModel, IBaseResourceModel, IFilterCriteria } from '..';

export interface IGame extends IBaseResourceModel {
	name: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface IGameContext extends IBaseApiTypeModel {}

export enum GameOutcomeResultType {
	Win = 'Win',
	Loss = 'Loss',
}

export enum GameType {
	SlotMachine = 'SlotMachine',
}

export enum GameTokenStatus {
	AvailableForUse = 'AvailableForUse',
	Redeemed = 'Redeemed',
}

export interface IGameOutcome {
	gameConfigId?: string;
	gameId: string;
	gameOutcomeResultType: GameOutcomeResultType;
	prize?: string;
	prizePaid?: boolean;
	prizePaidDate?: string;
	spinResult?: SlotMachineSymbol[];
	winningPayline?: ISlotMachinePayLine;
}

export interface IGameTemplate<T extends IGame = IGame> {
	game: T;
}

export interface IGameToken<TGameOutcome extends IGameOutcome = IGameOutcome> extends IBaseResourceModel {
	gameId: string;
	gameName: string;
	gameOutcome: TGameOutcome;
	gameType: GameType;
	redemptionDate?: string; // Date
	status: GameTokenStatus;
}

export interface IPrizeLog<TGameToken extends IGameToken = IGameToken> {
	achievementDescription?: string;
	achievementType: AchievementType;
	gameToken: TGameToken;
}

export enum GameTokenFilterCriteriaProperty {
	OutcomeDate = 'OutcomeDate',
	User = 'User',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	GameOutcomeResultType = 'GameOutcomeResultType',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	GameType = 'GameType',
	Status = 'Status',
	PrizePaid = 'PrizePaid',
	GameId = 'GameId',
}

export type IGameTokenFilter = IFilterCriteria<GameTokenFilterCriteriaProperty, FilterOperator>;

export enum SlotMachineSymbol {
	Tiger = 'Tiger',
	LevitateCoin = 'LevitateCoin',
	Seven = 'Seven',
	Cherry = 'Cherry',
	QuestionMark = 'QuestionMark',
}

export enum WinLimitTimeInterval {
	Daily = 'Daily',
	Monthly = 'Monthly',
}

export interface ISlotMachinePayLine {
	enabled: boolean;
	id?: string;
	prize?: string;
	symbol: SlotMachineSymbol;
	symbolCount: number;
	winLimit?: number;
	winLimitInterval?: WinLimitTimeInterval;
}

export interface ISlotMachineReelConfig {
	reelSymbols: SlotMachineSymbol[];
}

export interface ISlotMachineConfig {
	payTable: ISlotMachinePayLine[];
	reelConfiguration: ISlotMachineReelConfig[];
}

export enum SlotMachineType {
	Default = 'Default',
	BoozeCruise = 'BoozeCruise',
	GoldRush = 'GoldRush',
}

export interface ISlotMachine extends IGame {
	description: string;
	slotMachineConfig: ISlotMachineConfig;
	type: SlotMachineType;
}

export interface ISlotMachineSpin extends IGameOutcome {
	spinResult: SlotMachineSymbol[];
	winningPayline?: ISlotMachinePayLine;
}

export const DefaultSlotMachineGameTemplates: Partial<ISlotMachine>[] = [
	{
		_type: 'SlotMachine',
		description:
			'The default slot machine has a total of 5 symbols. The chance of winning (getting 3 to match) is slightly less than Booze Cruise and Gold Rush, which only have 3 symbols.',
		name: SlotMachineType.Default,
		slotMachineConfig: {
			payTable: [
				...[
					SlotMachineSymbol.Cherry,
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.QuestionMark,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Tiger,
				].map(symbol => ({
					enabled: false,
					symbol,
					symbolCount: 3,
				})),
				...[
					SlotMachineSymbol.Cherry,
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.QuestionMark,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Tiger,
				].map(symbol => ({
					enabled: false,
					symbol,
					symbolCount: 2,
				})),
			],
			reelConfiguration: Array.from({ length: 3 }).map(() => ({
				reelSymbols: [
					SlotMachineSymbol.Cherry,
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.QuestionMark,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Tiger,
					SlotMachineSymbol.Cherry,
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.QuestionMark,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Tiger,
				],
			})),
		},
		type: SlotMachineType.Default,
	},
	{
		_type: 'SlotMachine',
		description:
			'The Booze Cruise slot machine has a total of 3 symbols. The chance of winning (getting 3 to match) is higher than the default machine.',
		name: 'Booze Cruise',
		slotMachineConfig: {
			payTable: [
				...[SlotMachineSymbol.LevitateCoin, SlotMachineSymbol.Seven, SlotMachineSymbol.Cherry].map(symbol => ({
					enabled: false,
					symbol,
					symbolCount: 3,
				})),
				...[SlotMachineSymbol.LevitateCoin, SlotMachineSymbol.Seven, SlotMachineSymbol.Cherry].map(symbol => ({
					enabled: false,
					symbol,
					symbolCount: 2,
				})),
			],
			reelConfiguration: Array.from({ length: 3 }).map(() => ({
				reelSymbols: [
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Cherry,
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Cherry,
				],
			})),
		},
		type: SlotMachineType.BoozeCruise,
	},
	{
		_type: 'SlotMachine',
		description:
			'The Gold Rush slot machine has a total of 3 symbols. The chance of winning (getting 3 to match) is higher than the default machine.',
		name: 'Gold Rush',
		slotMachineConfig: {
			payTable: [
				...[SlotMachineSymbol.LevitateCoin, SlotMachineSymbol.Seven, SlotMachineSymbol.Cherry].map(symbol => ({
					enabled: false,
					symbol,
					symbolCount: 3,
				})),
				...[SlotMachineSymbol.LevitateCoin, SlotMachineSymbol.Seven, SlotMachineSymbol.Cherry].map(symbol => ({
					enabled: false,
					symbol,
					symbolCount: 2,
				})),
			],
			reelConfiguration: Array.from({ length: 3 }).map(() => ({
				reelSymbols: [
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Cherry,
					SlotMachineSymbol.LevitateCoin,
					SlotMachineSymbol.Seven,
					SlotMachineSymbol.Cherry,
				],
			})),
		},
		type: SlotMachineType.GoldRush,
	},
];

export type GameModelTypes =
	| 'Game'
	| 'GameTemplate'
	| 'GameContext'
	| 'GameToken'
	| 'SlotMachine'
	| 'SlotMachineSpin'
	| 'PrizeLog';
