import { IBaseResourceModel, IFileAttachmentWithURL } from '.';
import { IRawRichTextContentState } from '../../../view/models/Api';

export enum CustomPageType {
	BlogHome = 'BlogHome',
	BlogIndividualPage = 'BlogIndividualPage',
	BlogPost = 'BlogPost',
	BlogPreviewPage = 'BlogPreviewPage',
	Footer = 'Footer',
	General = 'General',
	Header = 'Header',
}
export interface CustomPageStyle extends IBaseResourceModel {
	type: CustomPageType;
	css?: string;
}
export interface FooterCustomization extends CustomPageStyle {
	backgroundColor?: string;
	textColor?: string;
	linkColor?: string;
	text?: IRawRichTextContentState;
	copyrightInfo?: string;
	links?: NavLink[];
	type: CustomPageType.Footer;
}
export interface HeaderCustomization extends CustomPageStyle, Record<string, any> {
	backgroundColor?: string;
	dropdown: TextAttributes;
	hoverColor?: string;
	menuLinks?: NavLink[];
	overallFont?: BlogFont;
	textColor?: string;
	type: CustomPageType.Header;
}

export interface BlogHomeCustomization extends CustomPageStyle {
	pageBackgroundColor?: string;
	headerImage?: IFileAttachmentWithURL;
	hideNook?: boolean;
	font?: BlogFont;
	blogTitle: CustomTitleText;
	cardStyle: BlogCard;
	type: CustomPageType.BlogHome;
}
export interface BlogPostCustomization extends CustomPageStyle {
	blogTitle?: TextAttributes;
	blogHeadlines?: TextAttributes;
	blogBody?: TextAttributes;
	showMoreButton?: PageButton;
	type: CustomPageType.BlogPost;
}
export interface NavLink {
	label?: string;
	destination?: string;
	children?: NavLink[];
}
export interface BlogFont {
	fontName?: string;
	fontUri?: string;
}
export interface TextAttributes {
	backgroundColor: string;
	font: BlogFont;
	color: string;
}
export interface PageButton extends TextAttributes {
	hoverColor: string;
}

export interface CustomTitleText extends TextAttributes {
	title: string;
}

interface BlogCard {
	titleStyle?: TextAttributes;
	linkStyle?: TextAttributes;
	showAuthorMetaData?: boolean;
}

export type CustomBlogStyles =
	| HeaderCustomization
	| FooterCustomization
	| BlogHomeCustomization
	| BlogPostCustomization;
